import Vue, { VueConstructor } from 'vue';
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter);

const vueInstances = {};

/**
 * Create singleton instance
 * @param root Root component
 * @param el Mount on element
 * @param options Additional vue options
 */
const createApp = (root: VueConstructor<Vue>, el: string, options: Record<string, any> = {}) => {
  if (!vueInstances[el])
    vueInstances[el] = new Vue({
      el,
      render: h => h(root),
      ...options,
    });

  return vueInstances[el];
};

/**
 * Mount Vue instance
 * @param root Root component
 * @param el Mount on element
 * @param options Additional vue options
 */
export const mountApp = (root: VueConstructor<Vue>, el: string, options: Record<string, any> = {}) => {
  document.addEventListener('turbo:load', () => createApp(root, el, options));
  return createApp(root, el, options);
};
